<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ `${$t('menu.booking')} / ${$t('btn.programation')}` }}
        </v-card-title>
        <v-card-text>
          <Search />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import Search from './form/Search.vue'

export default {
  components: {
    Search,
  },
}
</script>
