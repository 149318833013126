<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.operador') }}</strong>
          <v-autocomplete
            v-model="model.aerolinea_id"
            class="pt-2"
            :items="itemsAerolinea"
            :search-input.sync="searchAerolinea"
            hide-details
            hide-selected
            :label="$t('lbl.operador')"
            outlined
            dense
            item-text="name"
            item-value="id"
            single-line
            @change="changeAerolinea"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.numeroVuelo') }}</strong>
          <v-text-field
            ref="numero_de_vuelo"
            v-model="model.numero_de_vuelo"
            class="pt-2"
            :label="$t('lbl.numeroVuelo')"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="3"
          md="3"
        >
          <strong>{{ $t('lbl.from') }}</strong>
          <vc-date-picker
            v-model="model.from"
            class="pt-2"
            outlined
            dense
            :min-date="new Date()"
            mode="date"
            :model-config="modelConfig"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                :label="$t('lbl.from')"
                class="pt-2"
                outlined
                dense
                :value="inputValue"
                @change="changeFrom"
                v-on="inputEvents"
              >
              </v-text-field>
            </template>
          </vc-date-picker>
        </v-col>
        <v-col
          cols="12"
          sm="3"
          md="3"
        >
          <strong>{{ $t('lbl.to') }}</strong>
          <vc-date-picker
            v-model="model.to"
            class="pt-2"
            :disabled="!model.from"
            outlined
            dense
            :min-date="
              $moment(model.from)
                .add(1, 'day')
                .toDate()
            "
            mode="date"
            :model-config="modelConfig"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <v-text-field
                ref="to"
                :label="$t('lbl.to')"
                class="pt-2"
                outlined
                dense
                :value="inputValue"
                v-on="inputEvents"
              >
              </v-text-field>
            </template>
          </vc-date-picker>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <strong>{{ $t('lbl.ruta') }}</strong>
          <v-autocomplete
            v-model="model.origen"
            class="pt-2"
            :items="itemsOrigen"
            :search-input.sync="searchOrigen"
            hide-details
            hide-selected
            :label="$t('lbl.origen')"
            outlined
            dense
            item-text="name"
            item-value="id"
            single-line
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ noResultOrigen ? $t('lbl.noResultSearchFlight') : $t('lbl.origenSearch') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>
                {{ icons.mdiAirplaneTakeoff }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <strong style="color: #FFFFFF">{{ $t('lbl.ruta') }}</strong>
          <v-autocomplete
            v-model="model.destino"
            class="pt-2"
            :items="itemsDestino"
            :search-input.sync="searchDestino"
            hide-details
            hide-selected
            :label="$t('lbl.destino')"
            outlined
            dense
            item-text="name"
            item-value="id"
            single-line
            return-object
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ noResultDestino ? $t('lbl.noResultSearchFlight') : $t('lbl.destinoSearch') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>
                {{ icons.mdiAirplaneTakeoff }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <strong>{{ $t('lbl.semanaDay') }}</strong>
          <v-sheet class="py-2">
            <v-slide-group
              v-model="model.frecuencia"
              multiple
            >
              <v-slide-item
                v-for="(sema, index) in semanas"
                v-slot="{ active, toggle }"
                :key="index"
                class="px-1"
              >
                <v-btn
                  class=""
                  :input-value="active"
                  active-class="primary white--text"
                  depressed
                  rounded
                  x-small
                  @click="toggle"
                >
                  {{ sema }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-col>
      </v-row>
    </v-form>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="
              model.aerolinea_id ||
                model.numero_de_vuelo ||
                model.frecuencia.length > 0 ||
                model.origen ||
                model.destino ||
                model.from ||
                model.to
            "
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="text-center"
        >
          <v-btn
            :loading="loading"
            color="primary"
            @click="searchAll()"
          >
            <v-icon
              right
              dark
            >
              {{ icons.mdiMagnify }}
            </v-icon>
            <span class="pl-5">{{ $t('btn.search') }}</span>
          </v-btn>
        </v-col>
        <v-col
          v-if="hay_result"
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="searchAll()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <Flights v-if="scheduleResult.length > 0" />
    <v-skeleton-loader
      v-else-if="scheduleResult.length === 0 && hay_result"
      class="mx-auto"
      type="table-heading, list-item-two-line, image, table-tfoot"
    ></v-skeleton-loader>

    <v-card-text
      v-if="hay_result"
      class="pt-2"
    >
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="Math.round(pagination.total/itemsPerPage)"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js'
import Flights from '../tablas/Flights.vue'

export default {
  components: {
    Flights,
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      orderByVuelo: null,
      orderByAerolinea: null,
      orderByOrigen: null,
      orderByDestino: null,
      items: [],
      hay_result: false,
      pagination: {
        current: 1,
        total: 0,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      totalItems: 0,
      totalF: 0,
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
      },
      isDialogVisible: false,
      model: {
        aerolinea_id: null,
        numero_de_vuelo: null,
        frecuencia: [],
        origen: null,
        destino: null,
        from: null,
        to: null,
      },
      aerolineas: [],
      itemsAerolinea: [],
      searchAerolinea: null,
      semanas: [],
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      item: {},
      itemId: null,
      isDialogVisibleDelete: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: sessionStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      noResultdestino: false,
      noResultOrigen: false,
    }
  },
  computed: {
    ...mapState({
      scheduleResult: state => state.app.scheduleResult,
      pageResult: state => state.app.pageResult,
      itemsPerPageResult: state => state.app.itemsPerPageResult,
    }),
  },
  watch: {
    searchAerolinea(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterAerolinea(val.toLowerCase())
      } else {
        this.itemsAerolinea = []
      }
    },
    searchOrigen(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOrigen(val.toLowerCase())
      } else {
        this.noResultOrigen = false
      }
    },
    searchDestino(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestino(val.toLowerCase())
      } else {
        this.noResultDestino = false
      }
    },
  },
  created() {
    this.profile()
    this.getAerolineas()

    // this.loadAll()
    // this.load()

    this.itemsPerPage = this.itemsPerPageResult

    this.semanas.push('D')
    this.semanas.push('L')
    this.semanas.push('M')
    this.semanas.push('X')
    this.semanas.push('J')
    this.semanas.push('V')
    this.semanas.push('S')
  },
  methods: {
    ...mapMutations(['setScheduleResult', 'setSchedulePageResult', 'setScheduleItemsPerPageResult']),
    filterAerolinea(v) {
      this.itemsAerolinea = []
      if (v === '') {
        this.itemsAerolinea = []
      } else {
        this.itemsAerolinea = this.aerolineas.filter(e => e.name.toLowerCase())
      }
    },
    filterOrigen(v) {
      this.itemsOrigen = []
      if (v === '') {
        this.itemsOrigen = []
      } else {
        this.axios
          .post(
            'airports-names-filter-origen-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsOrigen.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })

            if (this.itemsOrigen.length === 0) {
              this.noResultOrigen = true
            }
          })
      }
    },
    filterDestino(v) {
      this.itemsDestino = []
      if (v === '') {
        this.itemsDestino = []
      } else {
        this.axios
          .post(
            'airports-names-filter-destino-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsDestino.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })

            if (this.itemsDestino.length === 0) {
              this.noResultDestino = true
            }
          })
      }
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    load() {
      this.loading = true
      this.setScheduleResult([])

      // this.axios.get(`flights?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${this.model}&perfil_slug=${sessionStorage.getItem('perfil')}`, {
      const json = {
        page: this.pageResult,
        per_page: this.itemsPerPage,
        aerolinea: this.model.aerolinea_id,
        numero_de_vuelo: this.model.numero_de_vuelo,
        frecuencia: this.model.frecuencia,
        origen: this.model.origen,
        destino: this.model.destino,
        from: this.model.from,
        to: this.model.to,
      }

      // console.log(json)
      if (this.model.from && this.model.to) {
        this.axios
          .post('cotizador-schedule-flight', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            // console.log(res)

            if (res.data.data.flights.length > 0) {
              this.hay_result = true
            } else {
              this.hay_result = false
            }

            this.items = res.data.data.flights
            this.pagination.current = res.data.data.page
            this.pagination.total = res.data.data.total
            this.setScheduleResult(this.items)
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loading = false))
      } else {
        this.$toast.error(this.$t('msg.dataSpecific'))
        this.loading = false
      }
    },
    changePerPage() {
      this.setScheduleItemsPerPageResult(this.itemsPerPage)

      this.load()
    },
    searchAll() {
      this.setSchedulePageResult(1)
      this.load()
    },
    getAerolineas() {
      this.axios
        .get(`nom_aerolines?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.aerolineas = res.data.data
        })
    },
    onPageChange() {
      this.setSchedulePageResult(this.pagination.current)
      this.load()
    },

    clearSearch() {
      this.model = {
        aerolinea_id: null,
        numero_de_vuelo: null,
        frecuencia: [],
        origen: null,
        destino: null,
        from: null,
        to: null,
      }
      this.setScheduleResult([])

      // this.load()
    },

    changeAerolinea() {
      const air = this.aerolineas.filter(a => a.id === this.model.aerolinea_id)[0]
      this.model.numero_de_vuelo = `${air.slug} `
      this.$refs.numero_de_vuelo.focus()
    },
    changeFrom() {
      this.$refs.to.focus()
    },

    showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
